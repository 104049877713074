import React from 'react';
import './css/head.css'

const Head = () => {
    return (
        <div>

            <div className="header">


                <div className="inner-header flex">

                    <h1>Bonjour cher visit(rice)(eur).</h1>
                </div>


                <div>

                </div>

            </div>

        </div>
    );
};

export default Head;